import React, { forwardRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Input from "../Input/inputs";
import { API_KEY_TINY_MCE } from "../../utils/env";

const CommonTextEditor = forwardRef(
  (
    {
      editorHeight = 400,
      value,
      onEditorChange,
      inputLabel,
      inputRows,
      inputName,
      inputOnChange,
      inputStyle,
      inputfullWidth,
      inputMultiline,
      inputDefaultValue,
      inputProps,
      isTextEditorEnabled = true,
      initialValue = "",
      error = null,
      helperTextMessage = "",
      onChangeTargetName = "instructions",
      plugins = [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount textcolor",
      ],
      toolbar = "undo redo | formatselect | image | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
      contentStyle = "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }",
    },
    ref
  ) => {
    return isTextEditorEnabled ? (
      <div>
        <Editor
          apiKey={API_KEY_TINY_MCE}
          onInit={(evt, editor) => {
            if (ref) ref.current = editor;
          }}
          value={value}
          initialValue={initialValue}
          onEditorChange={(content) => {
            const event = {
              target: {
                name: onChangeTargetName,
                value: content,
              },
            };
            if (onEditorChange) onEditorChange(event);
          }}
          error={error}
          helperText={helperTextMessage}
          init={{
            height: editorHeight,
            menubar: false,
            branding: false,
            plugins,
            toolbar,
            content_style: contentStyle,
          }}
        />
      </div>
    ) : (
      <div>
        <Input
          label={inputLabel}
          fullWidth={inputfullWidth}
          multiline={inputMultiline}
          rows={inputRows}
          name={inputName}
          onChange={inputOnChange}
          defaultValue={inputDefaultValue}
          style={{ ...inputStyle }}
          InputProps={{ ...inputProps }}
        />
      </div>
    );
  }
);

export default CommonTextEditor;
